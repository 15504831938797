import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';

export const Community = {
  shape: PropTypes.shape({
    communityId: PropTypes.string,
    project: PropTypes.string,
    communityName: PropTypes.string,
    active: PropTypes.bool,
    customerNumber: PropTypes.string,
    invoiceLevel: PropTypes.string,
    supplierId: PropTypes.string,
    agreementId: PropTypes.string,
    salesContactName: PropTypes.string,
    salesContactEmail: PropTypes.string,
    customerContactName: PropTypes.string,
    customerContactEmail: PropTypes.string,
    features: PropTypes.shape({
      onSiteEnabled: PropTypes.bool,
      generatorEnabled: PropTypes.bool,
      printOrderEnabled: PropTypes.bool,
      drawingManagementEnabled: PropTypes.bool,
      workspacesEnabled: PropTypes.bool,
      sharedUsersEnabled: PropTypes.bool,
      visualBoardsEnabled: PropTypes.bool,
      publicLinksEnabled: PropTypes.bool,
      mfaRequired: PropTypes.bool,
      externalPublishEnabled: PropTypes.bool,
      createRoomEnabled: PropTypes.bool,
      orderRoomEnabled: PropTypes.bool,
      bimContextEnabled: PropTypes.bool,
    }),
  }),
  defaultValue: Immutable({
    communityId: '',
    project: '',
    communityName: '',
    active: false,
    customerNumber: '',
    invoiceLevel: 'Workspace',
    supplierId: '',
    agreementId: '',
    salesContactName: '',
    salesContactEmail: '',
    customerContactName: '',
    customerContactEmail: '',
    features: {
      onSiteEnabled: false,
      generatorEnabled: false,
      printOrderEnabled: false,
      drawingManagementEnabled: false,
      workspacesEnabled: false,
      sharedUsersEnabled: false,
      visualBoardsEnabled: false,
      publicLinksEnabled: false,
      mfaRequired: false,
      externalPublishEnabled: false,
      createRoomEnabled: false,
      orderRoomEnabled: false,
      bimContextEnabled: false,
    },
  }),
};
